.buttonSearch {
  position: absolute;
  top: 4%;
  right: 16%;
  width: 40px;
  height: 40px;
}

.wishWrapper {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: default;
  cursor: grab;
}
.wishWrapper:active {
  cursor: grabbing;
}

.loader {
  position: absolute;
  width: 64px;
  height: 64px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loader:after {
  width: 50px;
  height: 50px;
  border-width: 10px;
}

/*
*** Overlay style
*/

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  z-index: 10;
  margin: 0 15px 0 15px;
  text-align: center;
  color: white;
  cursor: pointer;
}

@media (max-width: 425px) {
  .wrapper {
  }

  .title {
    font-size: 16px;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .wrapper {
  }

  .title {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .wrapper {
  }

  .title {
    font-size: 26px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .title {
    font-size: 30px;
  }
}

@media (min-width: 1440px) {
  .title {
    font-size: 36px;
  }
}
