	
.ldsDualRing {
	/* margin: 0 10px 0 10px; */
	display: inline-block;
	width: 20px;
	height: 20px;
}

.ldsDualRing:after {
	content: " ";
	display: block;
	width: 23px;
	height: 23px;
	/* margin: 1px; */
	border-radius: 50%;
	border: 5px solid #08abe4;
	border-color: #08abe4 transparent #08abe4 transparent;
	animation: ldsDualRing 1.2s linear infinite;
}

@keyframes ldsDualRing {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
