@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
  margin: 0;
  padding: 0;
  /* height: 100vh; */
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  background-color: transparent !important;
}
#root {
  display: flex;
  /* min-height: 100%; */
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #88888821;
  border-radius: 27px
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888880; 
  border-radius: 27px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #767676; 
}