
.overlayContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
/*	animation-name: appear;
	animation-duration: 0.25s;
	animation-fill-mode: forwards;*/
}

.overlay {
	position: absolute;
	background-color: rgba(75, 180, 255, 0.75);
	opacity: 1;
	animation-name: grow;
	animation-duration: 0.4s;
	animation-fill-mode: forwards;
}

.disapear {
	opacity: 0;
	transition: opacity 0.4s;
}

.content {
	opacity: 0;
	animation-name: appear;
	animation-delay: 0.4s;
	animation-duration: 0.25s;
	animation-fill-mode: forwards;
}

@keyframes grow {
	from {
		top: 50%;
		right: 50%;
		bottom: 50%;
		left: 50%;
	}

	to {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

@keyframes appear {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}