@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);
	
.ldsDualRing {
	/* margin: 0 10px 0 10px; */
	display: inline-block;
	width: 20px;
	height: 20px;
}

.ldsDualRing:after {
	content: " ";
	display: block;
	width: 23px;
	height: 23px;
	/* margin: 1px; */
	border-radius: 50%;
	border: 5px solid #08abe4;
	border-color: #08abe4 transparent #08abe4 transparent;
	-webkit-animation: ldsDualRing 1.2s linear infinite;
	        animation: ldsDualRing 1.2s linear infinite;
}

@-webkit-keyframes ldsDualRing {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes ldsDualRing {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}


.wrapper {
	position: absolute;
	z-index: 10;
}

.overlay {
	background-color: rgba(35, 201, 255, 0.85);
	/*transition: opacity 0.4s ease-out, margin 0.2s ease-in, width 0.2s ease-in, height 0.2s ease-in;*/
	opacity: 0;
}

.overlay > div {
	opacity: 0;
}


.overlayContainer {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
/*	animation-name: appear;
	animation-duration: 0.25s;
	animation-fill-mode: forwards;*/
}

.overlay {
	position: absolute;
	background-color: rgba(75, 180, 255, 0.75);
	opacity: 1;
	-webkit-animation-name: grow;
	        animation-name: grow;
	-webkit-animation-duration: 0.4s;
	        animation-duration: 0.4s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

.disapear {
	opacity: 0;
	transition: opacity 0.4s;
}

.content {
	opacity: 0;
	-webkit-animation-name: appear;
	        animation-name: appear;
	-webkit-animation-delay: 0.4s;
	        animation-delay: 0.4s;
	-webkit-animation-duration: 0.25s;
	        animation-duration: 0.25s;
	-webkit-animation-fill-mode: forwards;
	        animation-fill-mode: forwards;
}

@-webkit-keyframes grow {
	from {
		top: 50%;
		right: 50%;
		bottom: 50%;
		left: 50%;
	}

	to {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

@keyframes grow {
	from {
		top: 50%;
		right: 50%;
		bottom: 50%;
		left: 50%;
	}

	to {
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

@-webkit-keyframes appear {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes appear {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
.buttonSearch {
  position: absolute;
  top: 4%;
  right: 16%;
  width: 40px;
  height: 40px;
}

.wishWrapper {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: default;
  cursor: grab;
}
.wishWrapper:active {
  cursor: grabbing;
}

.loader {
  position: absolute;
  width: 64px;
  height: 64px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loader:after {
  width: 50px;
  height: 50px;
  border-width: 10px;
}

/*
*** Overlay style
*/

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  z-index: 10;
  margin: 0 15px 0 15px;
  text-align: center;
  color: white;
  cursor: pointer;
}

@media (max-width: 425px) {
  .wrapper {
  }

  .title {
    font-size: 16px;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .wrapper {
  }

  .title {
    font-size: 24px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .wrapper {
  }

  .title {
    font-size: 26px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .title {
    font-size: 30px;
  }
}

@media (min-width: 1440px) {
  .title {
    font-size: 36px;
  }
}

.videoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	height: 100%;
	width: 100%;
}

#tutorial {
	width: 60vw;
	height: 60vh;
}

@media screen and (max-width: 1023px) {
	#tutorial {
		width: 75vw;
		height: 75vh;
	}
}

@media screen and (max-width: 600px) {
	#tutorial {
		width: 90vw;
		height: 90vh;
	}
}

html {
  margin: 0;
  padding: 0;
  /* height: 100vh; */
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  background-color: transparent !important;
}
#root {
  display: flex;
  /* min-height: 100%; */
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #88888821;
  border-radius: 27px
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888880; 
  border-radius: 27px
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #767676; 
}
