.videoContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	height: 100%;
	width: 100%;
}

#tutorial {
	width: 60vw;
	height: 60vh;
}

@media screen and (max-width: 1023px) {
	#tutorial {
		width: 75vw;
		height: 75vh;
	}
}

@media screen and (max-width: 600px) {
	#tutorial {
		width: 90vw;
		height: 90vh;
	}
}
